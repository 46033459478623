import './bootstrap';
import '../css/app.css';
import 'vue-select/dist/vue-select.css';
import '@Assets/css/vue-select.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import Notifications from 'notiwind';
import VueCreditCardValidation from 'vue-credit-card-validation';
import ClickOutside from '@Core/Composables/clickOutside';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import vSelect from 'vue-select';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    progress: {
        color: '#428bca'
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/Dashboard/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, window.ziggy)
            .use(Notifications)
            .use(VueCreditCardValidation)
            .component('vSelect', vSelect)
            .directive('click-outside', ClickOutside)
            .provide('$swal', Swal)
            .mount(el);
    }
});

if (import.meta.env.VITE_SENTRY_ENABLED) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
        release: 'bluebook@v.1.0.0',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0
    });
}
